.headshot {
  width: 100%;
  height: 100%;
}

.headshotlink,
.headshotlink:focus,
.headshotlink:active,
.headshotlink:hover {
  position: absolute;
  top: 1.5rem;
  left: 0.5rem;
  width: calc(9rem + 4px);
  height: calc(9rem + 4px);
  border-radius: 50%;
  border: 2px solid white;
  overflow: hidden;
}

.header {
  background-color: var(--blue);
  height: 6rem;
}

.header code,
.title code {
  background: none;
  border: none;
}

.headertitle {
  font-size: 2rem;
  padding-top: 1.8rem;
  text-align: center;
  color: white;
  font-weight: bold;
}

@media screen and (min-width: 550px) {
  .headshotlink,
  .headshotlink:focus,
  .headshotlink:active,
  .headshotlink:hover {
    left: 1.5rem;
  }
}

.social-links {
  position: absolute;
  top: 1.6rem;
  display: flex;
  right: 0;
}

.social-links > a {
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
}

.social-links svg {
  width: 2rem;
  height: 2rem;
  color: white;
}
