body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Menlo, Consolas, Monaco, "Liberation Mono", "Lucida Console", monospace;
  padding: 0.5rem;
}

#root {
  overflow: hidden;
}

:root {
  --blue: #2e86f9;
  --light-blue: #c4deff;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.title {
  font-weight: lighter;
  color: rgb(129, 129, 129);
}

.main {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.link-no-style,
.link-no-style:active,
.link-no-style:visited,
.link-no-style:hover,
.link-no-style:focus {
  color: white;
  text-decoration: none;
}

.button-no-style,
.button-no-style:hover {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
}

a, a:active, a:visited, a:focus {
  color: var(--blue);
  opacity: 1;
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
  text-decoration: none;
}

h4 {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

h5 {
  margin-bottom: 0;
  font-weight: bold;
}

h4, h5 {
  letter-spacing: normal;
}

ul {
  margin-left: 1rem;
  margin-bottom: 0;
  list-style: disc inside;
}

ul ul,
ol ul {
  font-size: 100%;
  margin: 0rem 0 0rem 2rem;
  list-style: circle inside;
}

ul li,
ol li {
  font-weight: bold;
  margin-bottom: 0.25rem;
  margin-top: 0.75rem;
}

ul ul li,
ol ul li {
  font-weight: normal;
  margin-top: 0;
  opacity: 0.6;
}

/* @media screen and (min-width: 550px) {
  .title {
    font-size: 2rem;
  }

  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.75rem;
  }
} */

body {
  font-size: 16px;
  line-height: 1.35;
  letter-spacing: 0.2px;
}

.title {
  font-size: 20px;
}

h4 {
  font-size: 28px;
}
h5 {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 28px;
  }

  h4 {
    font-size: 32px;
  }
  h5 {
    font-size: 24px;
  }
}

/* @media screen and (min-width: 960px) {
  .title {
    font-size: 3rem;
  }

  h4 {
    font-size: 3rem;
  }
  h5 {
    font-size: 2.5rem;
  }
} */

@media screen and (max-width: 960px) {
  .main {
    width: 100%; /* overwrite .container */
    padding: 0;
  }
  .row {
    padding: 0 1.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: white;
    background: #0d1117;
  }
  code {
    background: #585858;
    border: 1px solid #333;
  }
}

.reset {
  margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
